import React from 'react';
import styled from 'styled-components';
import { HeroBanner } from '../components/HeroBanner';
import { SEO } from '../components/SEO';
import { Container } from '../components/styled/Container';

const PageWrapper = styled.div`
  max-width: 65ch;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  padding: 0 1rem;
`;

const BuyMeABeer = styled.a`
  display: inline-block;
  padding: 0.35rem 0.75rem;
  background-color: var(--control-bg-color-active);
  border: 1px solid var(--control-border-color-active);
  cursor: pointer;
  transition: all 200ms ease-in-out;
  div {
    display: flex;
    justify-items: center;
    align-items: center;
    gap: 1rem;
    color: var(--control-text-color-active);
    .large {
      font-size: 1.25rem;
    }
  }
`;

export default function about() {
  return (
    <PageWrapper>
      <SEO title={'About'} />
      <div>
        <HeroBanner text={['About CTA Fans']} size={3} />
        <p>
          Crush Them All fans are the best. We love to crush things, especially
          when it's enemy teams and boss fights.
        </p>
      </div>
      <div>
        <HeroBanner text={['About This Site']} size={3} />
        <p>This site is currently a work in a work-in-progress.</p>
        <p>
          In addition to general usability improvements, there are several
          improvements planned for the future.
        </p>
        <p>In no order of importance, they are:</p>
        <ul>
          <li>Ability to compare multiple heroes</li>
          <li>
            Team Builder which would allow you to build teams and see how their
            buffs interact with each other
          </li>
          <li>Ability to rate heroes</li>
          <li>Ability to rate team configurations</li>
          <li>Ability to share team configurations through URLs</li>
          <li>Ability for the community to create guides</li>
          <li>Ability to show stats from Runes</li>
        </ul>
      </div>
      <div>
        <HeroBanner text={['About me']} size={3} />
        <p>
          I'm just a guy that likes to build things and crush the opposition. If
          you like what I do and want to support CTAFans, you can{' '}
          <a
            href='http://buymeacoffee.com/genehenson'
            target='_blank'
            rel='noopener noreferrer'
          >
            buy me a beer
          </a>
          . (All proceeds go to paying the costs for this site...and buying me
          beer.) You can also find me on{' '}
          <a
            href='http://twitter.com/genehenson'
            target='_blank'
            rel='noopener noreferrer'
          >
            Twitter
          </a>
          .
        </p>
        <BuyMeABeer
          href='http://buymeacoffee.com/genehenson'
          target='_blank'
          rel='noopener noreferrer'
        >
          <div>
            <span className={'large'}>🍺</span>
            <span>Buy Me a Beer</span>
          </div>
        </BuyMeABeer>
      </div>
    </PageWrapper>
  );
}
